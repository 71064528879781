import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonUtils from "../../../utility/common-utilities";
import "./tender-card.scss";
import { fa } from "@fortawesome/free-solid-svg-icons";
import "../../../styles/fonts.scss";

const TenderCard = (props) => {
  let tender = props.tender;
  let terms = tender.terms || {}
  let buyerTender = props.tender.buyer_tender ? props.tender.buyer_tender[0] : null;
  let services = props.tender?.selectedServices || [];

  return (
    <div className="card ">
      <div className="card-header">
        <div className="row ">
          <div className="col-6 col-md-8">
            <div className="row">
              <div className="col-12 col-md-6">
                <span className=" obx-text-dgray text card_title_head">
                  <b>{tender?.facilityName}</b> ( Tender No : {tender.tenderNumber}{" "}
                  )
                </span>
              </div>
              <div className="col-12 col-md-6 text-center">
                <span style={{textTransform: "uppercase", fontWeight: 'bold', color: '#041359'}}>{terms?.contractType ?  `${terms?.contractType} Tender` : ""}</span>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4">
            <button
              type="button"
              className="btn btn-sm btn-primary obx-btn-color float-end button-title"
              onClick={() => props.onView(tender)}
            >
              View Tender
            </button>
            {props.showBookmark ? (
              <span className="float-end mr-2">
                {" "}
                <span
                  className={`cursor-pointer ${
                    tender.isBookmark ? "active-bookmark" : "inactive-bookmark"
                  }`}
                  onClick={() => props.onClickBookmark(tender)}
                >
                  {" "}
                  <i
                    class={
                      tender.isBookmark
                        ? "bi bi-bookmark-fill"
                        : "bi bi-bookmark"
                    }
                  >
                    {" "}
                  </i>{" "}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="card-body ">
        <div className="row">
          <div className="col-8 mb-1 text ">
            {/* <div className="pr-2 d-inline-block text"><span className="obx-text-dgray">Status: </span><b>{tender.status}</b> </div> */}
            <div className="pr-2 text card_title_subhead">
              <span className=""></span>
              <b>{CommonUtils.camelCase(tender.facilityType || "")}</b>
            </div>
            <div className="pr-2 text card_title_subhead_category">
              <span className="text"></span>
              {tender.streetAddress}
            </div>
          </div>
          <div className="col-4 text-end text card_title_content">
            <span className="me-2 badge text-bg-light obx-text-dgray">
              Published On:{" "}
              {new Date(tender.terms?.publicationDate).toLocaleDateString()}
            </span>
            <span className="me-2 badge text-bg-light obx-text-dgray">
              Closing On:{" "}
              {new Date(
                tender.terms?.submissionDeadlineDate
              ).toLocaleDateString()}
            </span>
            <p className="mt-3 mr-2" style={{textAlign:"right"}}>{buyerTender?.createBY==="Custom"?"User Customed Tender":null}</p>
          </div>
        </div>
        <div className="row pt-2">
          <hr />
          <div className="col-lg-2 col-xl-2">
            <span className="obx-text-dgray text card_title_subhead">Services</span>
          </div>
          <div className="col-lg-6 col-xl-6 card_title_content">
            {services.map((service) => (
              <span className="me-2 badge text-bg-warning obx-text-dgray text" style={{background:"red"}}>
                {service?.title}
              </span>
            ))}
          </div>
          <div className="col-lg-2 col-xl-2 ms-auto d-flex flex-wrap align-content-center justify-content-end">
            <span className={`tender-status-badge ${tender.status.toLowerCase()} position-relative top-0 text-capitalize`}>
              {tender.status.toLowerCase()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderCard;
