import React, { useState } from 'react';
import { Form, Accordion, useAccordionButton, Card } from "react-bootstrap";
import AsyncDropdown from '../../common/dropdown/async-dropdown';

const VendorFilter = ({ handleFilter = ()=>{} }) => {
  const [OBCReady, setOBCReady] = useState(null)
  const [manpower, setManpower] = useState(null)
  const [financialGrade, setFinancialGrade] = useState(null)
  const [FGAmount, setFGAmount] = useState(null)
  const [plrd, setPlrd] = useState([]) 

  let electives = [
    {value: 1, label: "Continuous Professional Development" },
    {value: 2, label: "Simulation Training" },
    {value: 3, label: "Job Redesign" },
    {value: 4, label: "Business Continuity Plan" },
    {value: 5, label: "Security Checks" },
    {value: 6, label: "Standard Operating Procedures (SOPs)" },
    {value: 7, label: "Service Buyer Management Process" },
    {value: 8, label: "Supervision" },
    {value: 9, label: "Body Worn Cameras / Wireless Communications" },
    {value: 10, label: "Vvalueeo Analytics" },
    {value: 11, label: "Remote Guarding" },
    {value: 12, label: "Technology Enabled Patrol" },
  ]
  

  let manpowers = [
    {value: "<200", label: "Below 200"},
    {value: "200-500", label: "200 - 500"},
    {value: ">500", label: "Above 500"},
  ]

  let financialGrades = [
    { grade: "S2", amount: "$100,000"},
    { grade: "S3", amount: "$250,000"},
    { grade: "S4", amount: "$500,000"},
    { grade: "S5", amount: "$1,000,000"},
    { grade: "S6", amount: "$3,000,000"},
    { grade: "S7", amount: "$5,000,000"},
    { grade: "S8", amount: "$10,000,000"},
    { grade: "S9", amount: ">$10,000,000"},
    { grade: "S10", amount: ">$15,000,000"},
    { grade: "Ungrade", amount: null}
  ]


  const onApplyFilter = () => {
    handleFilter({
      plrd,
      gradeAmount: FGAmount,
      manpower,
      obc_ready: OBCReady,
    })
  }

  const handleResetFilter = () => {
    setPlrd([])
    setFinancialGrade("")
    setOBCReady("")
    setManpower("")
    setFGAmount("")
    handleFilter({
      plrd: [],
      gradeAmount: "",
      manpower: "",
      obc_ready: "",
    })
  }

  const handleCompanyChange = () => {}

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    );
  
    return (
      <button
        type="button"
        style={{ backgroundColor: 'pink' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  return (
    <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Advanced Filter</Accordion.Header>
        <Accordion.Body>
          <div className='row'>
            <div className="col-12 col-md-4">
              <div class="form-floating">
                <Form.Select
                  id="mp_select" 
                  aria-label="mp selection"
                  value={manpower}
                  onChange={(e) => {
                    setManpower(e.target.value ?? "")
                  }}
                >
                  <option value="" style={{color: "grey"}}>Select Manpower</option>
                  {manpowers.map((mp, id) => 
                      <option value={mp.value} key={id}>{mp.label}</option>
                    )
                  }
                </Form.Select>
                <label for="mp_select">Manpower Strength</label>
              </div> 
              
              <div 
                className="obc-ready mt-3" 
                style={{
                  border: "1px solid #dee2e6", 
                  borderRadius: "0.375rem", 
                  padding: ".2rem 2.25rem .2rem .75rem"
                }}
              >
                <label style={{fontSize: "0.9rem", color: "#737679"}}>OBC Ready</label>
                <div>
                  <div class="form-check form-check-inline" onChange={() => setOBCReady(true)}>
                    <input class="form-check-input" type="radio" name="obc_ready_options" id="obcYes" value="yes" required checked={OBCReady === true}/>
                    <label class="form-check-label" for="obcYes">Yes</label>
                  </div>
                  <div class="form-check form-check-inline"  onChange={() => setOBCReady(false)}>
                    <input class="form-check-input" type="radio" name="obc_ready_options" id="obcNo" value="no" required checked={OBCReady === false} />
                    <label class="form-check-label" for="obcNo">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div class="form-floating">
                <Form.Select
                  id="financial_select" 
                  aria-label="financial selection"
                  value={financialGrade}
                  onChange={(e) => {
                    setFinancialGrade(e?.target?.value || "")
                    let findFGA = financialGrades.find(v => v.grade === e?.target?.value || "")
                    setFGAmount(findFGA ? findFGA.amount : null)
                  }}
                >
                  <option value="" style={{color: "grey"}}>Select Grades</option>
                  {financialGrades.map((fg, id) => 
                      <option value={fg.grade} key={id}>{fg.grade} {fg.amount && `(${fg.amount})`}</option>
                    )
                  }
                </Form.Select>
                <label for="financial_select">Financial Grades</label>
              </div> 
              {
                financialGrade === "Ungrade" &&
                <div className="form-floating mt-3 mb-3">
                  <input
                    type="number"
                    className="obx-small form-control "
                    id="fgAmount"
                    name="fgAmount"
                    value={FGAmount}
                    onChange={(e) => setFGAmount(e.target.value)}
                    required
                  />
                  <label className="obx-small" htmlFor="fgAmount">
                    Enter Amount ($) *
                  </label>
                </div>
              }
            </div>

            <div className="col-12 col-md-4">
              <AsyncDropdown
                data={electives}
                className={"plrd"}
                label={"PLRD Electives"}
                handleChange={(selectedOptions) => {
                  setPlrd(selectedOptions)
                }}
                value={plrd}
              />
            </div>
          </div>
          
          <div className='row'>
            <div className="col">
              <button
                onClick={onApplyFilter}
                className={`btn btn-sm btn-dark align-center vendor-button float-end`}
              >
                {`Apply Filter`}
              </button>
              <button
                onClick={handleResetFilter}
                type="button"
                className="btn btn-sm btn-light float-end mr-2"
              >
                Reset
              </button>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default VendorFilter