import React, { Component } from 'react';
import VendorList from '../../common/list/vendor-list';
import './vendor-list-card.scss'
const VendorAddRemoveCard = ({
	companyName,
	handleSelectVendor,
	isLoading,
	onChangeCompName,
	onRemoveVendor,
	onSelectVendor,
	observerRef,
	selectedVendorList,
	vendors,
}) => {
	return (
		<div className='row mt-3'>
			<div className='col-12 col-md-6'>
				<div className='card'>
					<div  className="card-header">
						<h5 className='card-title'>List of Selected Vendor</h5>
					</div>
					<div className='card-body'>
						<VendorList 
							data={selectedVendorList} 
							onRemoveVendor={onRemoveVendor}
							unableRemoved={true}
						/>
					</div>
				</div>
			</div>

			<div className='col-12 col-md-6'>
				<div className='card'>
				<div  className="card-header">
						<h5 className='card-title'>List of Vendor</h5>
					</div>
					<div className='card-body'>
						<div className="form-floating mb-3">
							<input
								type="text"
								className="form-control"
								id="floatingInput"
								placeholder="Company Name"
								onChange={(e) => {
									onChangeCompName(e?.target?.value ?? "");
								}}
								value={companyName}
							></input>
							<label htmlFor="floatingInput">Company Name</label>
						</div>
						<div>
							<VendorList 
								data={vendors} 
								onSelect={handleSelectVendor} 
								isLoading={isLoading}
								observerRef={observerRef}
								onSelectVendor={onSelectVendor}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VendorAddRemoveCard