import React from "react";
import "./app-confirm.scss";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";

class ConfirmModal extends React.Component {
  state = {};

  onOk = () => {
    this.props.onOk();
  };

  render() {
    return (
      <Modal
        className={`app-confirm-custom-modal ${this.props.className}`}
        show={true}
        toggle={this.props.close}
      >
        <Modal.Body
          className=''
          style={
            this.props.title ==
            "A $15.90 fee applies for submitting your proposal."
              ? { width: "24rem" }
              : { width: "35rem !important" }
          }
        >
          <div className='app-confirm'>
            <div>
              <img
                className='icon-20 close-icn cursor-pointer'
                alt=''
                src={getImageSource(ImageConstants.CLOSE_GRAY)}
                onClick={this.props.close}
              />
            </div>
            {this.props.img ? (
              <div className='text-center pt-4'>
                <img
                  className='icon-80 animate-image-1'
                  alt=''
                  src={this.props.img}
                />
              </div>
            ) : (
              ""
            )}
            <div className='text-center pt-2 px-4'>
              <div className='header-text'>
                {this.props.headerText || "Confirmation"}
              </div>
              <div
                className='description-text'
                style={
                  this.props.title ===
                  "A $12.90 fee applies for submitting your proposal."
                    ? null
                    : {
                        fontSize: "16px",
                        color: "#414141",
                        lineHeight: "22px",
                        marginTop: "14px",
                      }
                }
              >
                {this.props.title ||
                  "Are you sure do you want to do this Action?"}
                {this.props.title ==
                "A $15.90 fee applies for submitting your proposal." ? null : (
                  <p style={{ marginTop: "18px" }}>
                    Please note that once the tender is published, you will not
                    be able to edit the tender document directly except by
                    publishing a supporting corrigendum. Do make sure that you
                    have read through the tender document before proceeding to
                    publish.
                  </p>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <ModalFooter>
          <div className='row m-0 cursor-pointer button-boxes'>
            <div
              className='col-5 text-center border-right p-3 cancel-button'
              onClick={this.props.close}
            >
              {this.props.cancelButtonText || "Cancel"}
            </div>
            <div
              style={
                this.props.title ==
                "A $15.90 fee applies for submitting your proposal."
                  ? { fontSize: "14px" }
                  : { fontSize: "16px" }
              }
              className={`col-6 text-center processd-checkout p-3 ok-button ${
                this.props.okButtonStyle || ""
              }`}
              onClick={this.onOk}
            >
              {this.props.okButtonText || "Okay"}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmModal;
