import HttpClient from './http-client';
import URL from './url';

export default class AdminSettings {

    static getBuyerSubscriptionSetting(payload) {
        return HttpClient.get(URL.BASE_URL + URL.BUYER_SUBSCRIPTION_SETTING);
    }

    static getVendorSubscriptionSetting(payload) {
        return HttpClient.get(URL.BASE_URL + URL.VENDOR_SUBSCRIPTION_SETTING, payload);
    }

    static updateBuyerSubscriptionSetting(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BUYER_SUBSCRIPTION_SETTING, null, payload);
    }

    static updateVendorSubscriptionSetting(payload) {
        return HttpClient.put(URL.BASE_URL + URL.VENDOR_SUBSCRIPTION_SETTING, null, payload);
    }

    static getBuyerTenderPaymentSetting(payload) {
        return HttpClient.get(URL.BASE_URL + URL.BUYER_TENDER_PAYMENT_SETTING , payload);
    }

    static getVendorbiddingPaymentSetting(payload) {
        return HttpClient.get(URL.BASE_URL + URL.VENDOR_BIDDING_PAYMENT_SETTING, payload);
    }

    static updateBuyerTenderPaymentSetting(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BUYER_TENDER_PAYMENT_SETTING, null, payload);
    }

    static updateVendorbiddingPaymentSetting(payload) {
        return HttpClient.put(URL.BASE_URL + URL.VENDOR_BIDDING_PAYMENT_SETTING, null, payload);
    }

    static getTenderSetting(payload) {
        return HttpClient.get(URL.BASE_URL + URL.TENDER_SETTING + "?" + payload);
    }
}
