import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Urls from "../../../apis/url";
import ToasterComponent from '../toaster/toaster';

const MultiFileUpload = ({
  accept='',  
  onUpload=()=>{}, 
  onUploaded=()=>{},
  defSubmitBtn=true,
}) => {
  let token =window.localStorage.getItem('user')
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [showToast, setShowToast] = useState({type: null, message: null});
  const [submitted, setSubmitted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    if(uploadedFiles.length > 0) {
      let  uploadedFilesCopy = uploadedFiles.map(f => ({
        id: f.id,
        pdfDocumentKey: f.pdfDocumentKey,
        pdfDocumentUrl: f.pdfDocumentUrl
      }))
      onUploaded(uploadedFilesCopy)
    }
  }, [uploadedFiles])

  const onDrop = (acceptedFiles, rejectedFiles) => {

    if(rejectedFiles.length > 0) {
      setShowToast({ type: 'error', message: 'File type not accepted. Only .pdf, .doc, and .docx files are allowed.' });

      setTimeout(() => {
        setShowToast({ type: null, message: null });
      }, 2000);
    }
    const newFiles = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      id: Math.random().toString(36).substring(7), // Unique ID for each file
    }));
    
    setFiles([...files, ...newFiles]);
    handleUpload(newFiles)
  };

  const handleUpload = (newFiles) => {
    if(newFiles.length === 0) {
      setShowToast({ type: 'error', message: 'No files should be upload.' });

      setTimeout(() => {
        setShowToast({ type: null, message: null });
      }, 2000);
      return
    }

    if(submitted) {
      setShowToast({ type: 'error', message: 'Files has been uploaded.' });

      setTimeout(() => {
        setShowToast({ type: null, message: null });
      }, 2000);
      return
    }

    newFiles.forEach((fileObj) => {
      const formData = new FormData();
      formData.append('file', fileObj.file);
      
      fetch(`${Urls.BASE_URL}${Urls.UPLOADFILE}`, {
        method: 'POST',
        headers:{
          authorization: token,
        },
        body: formData,
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Upload failed');
        }
        return response.json();
      })
      .then((data) => {
        setUploadedFiles(prev => [...prev, {...data, fId: fileObj.id}])
        setUploadProgress(prev => ({
          ...prev,
          [fileObj.id]: 100
        }));
      })
      .catch((error) => {
        console.error('Error:', error);
        setUploadProgress(prev => ({
          ...prev,
          [fileObj.id]: 'failed'  // Mark as failed
        }));
      });
    });
  };

  const handleRemoveFile = (fileId) => {
    const fileToDelete = uploadedFiles.find(file => file.fId === fileId);

    if (fileToDelete) {
      fetch(`${Urls.BASE_URL}${Urls.DELETEFILE}`, {
        method: 'DELETE',
        headers: {
          authorization: token,
        },
        body: JSON.stringify({
          key: fileToDelete.pdfDocumentKey,
        })
      })
      .then((response) => {
        setFiles(files.filter(file => file.id !== fileId));
        setUploadedFiles(uploadedFiles.filter(file => file.id !== fileToDelete.id));
      })
      .catch((error) => {
        console.error('Error:', error);
        setShowToast({ type: 'error', message: 'Failed to delete file.' });

        setTimeout(() => {
          setShowToast({ type: null, message: null });
        }, 2000);
      });
    } else {
      setFiles(files.filter(file => file.id !== fileId));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop,
    accept:{
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
  });

  return (
    <div style={containerStyle} className={`card`}>
      <div className="card-body">
        {files.map(fileObj => {
          let file = fileObj.file
          return (
            <div key={fileObj.id} style={fileUploadStyle} className='row'>
              <div className="col-5">
                <div className='d-flex'>
                  {(file?.type && file?.type.includes('image')) &&
                    <img src={fileObj.preview} alt="Preview" style={imageStyle} />
                  }
                  <span className=''>{file?.name ?? ''}</span>
                </div>
              </div>
              <div className="col-6">
                <ProgressBar now={uploadProgress[fileObj.id] || 0} label={`${uploadProgress[fileObj.id] || 0}%`} />
              </div>
              <div className="col-1">
                {
                  uploadProgress[fileObj.id] === 100 &&
                    <button onClick={() => handleRemoveFile(fileObj.id)} style={removeButtonStyle} >X</button>
                }
              </div>
            </div>
          )
        })}

         <div className="header_title_caption">  {
          files.length === 0 &&
          <span>
            Upload additional File
          </span>
        }</div> 
      
        <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
          <input {...getInputProps()} />
          <button type="button"  className='btn btn-success' disabled={submitted}>Add Files</button>
        </div>

        {/* {
          defSubmitBtn &&
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <button 
              type='button' 
              onClick={handleUpload} 
              style={submitButtonStyle} 
              disabled={submitted}
            >
             { submitted ? 'Submitted' : 'Submit File'}
            </button>
            <span style={infoStyle}>Files have been submitted and can no longer be added or removed.</span>
          </div>
        } */}
      </div>

      {showToast?.message && (
        <ToasterComponent type={showToast?.type} message={showToast?.message} />
      )}
    </div>
  );
};

// CSS-in-JS styles to match the provided layout

const containerStyle = {
  // width: '400px',
  // margin: 'auto',
  // padding: '20px',
  // border: '1px solid #e0e0e0',
  // borderRadius: '5px',
};

const dropzoneStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px',
  marginTop: '20px',
};

const fileUploadStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  borderBottom: '1px solid #e0e0e0',
  paddingBottom: '10px',
};

const imageStyle = {
  width: '50px',
  height: '50px',
  borderRadius: '5px',
  objectFit: 'cover',
  marginRight: '10px',
};

const infoStyle = {
  color: 'green',
  marginTop: '10px',
};

const removeButtonStyle = {
  backgroundColor: '#fff',
  border: 'none',
  color: '#ff0000',
  fontWeight: 'bold',
  cursor: 'pointer',
  marginLeft: '10px',
};


const addButtonStyle = {
  padding: '8px 16px',
  backgroundColor: '#e0e0e0',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
};

const submitButtonStyle = {
  backgroundColor: '#0087F7',
  color: '#fff',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
};

export default MultiFileUpload;
