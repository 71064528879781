import { auto } from "@popperjs/core";
import { useState, forwardRef, useEffect } from "react";
import CommonUtils from "../../utility/common-utilities";
import "../../styles/fonts.scss";
// import "../create-tender-layout.scss";
import Accordion from "react-bootstrap/Accordion";
// import SecurityServices from "../../../constants/securityservicelogo";
import SecurityServices from "../../constants/securityservicelogo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import SiteDetails from "../create-tender/site-details/site-details";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faGlobe } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../common/app-confirm/app-confirm";
import UploadTender from "./uploadTender";
import TenderAPI from "../../apis/tender";
import { debounce } from "lodash";
import Urls from "../../apis/url";
import MultiFileUpload from "../common/upload/multiple-upload";
import VendorCard from "../cards/vendor-list-card/vendor-card";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMessage,
//   faFileAlt,
//   faSave,
//   faGlobe,
//   faEdit,
//   faEye,
//   faSpinner,
//   faClose,
// } from "@fortawesome/free-solid-svg-icons";

const BuildingParticular = (props) => {
  const token =window.localStorage.getItem('user')
  const [payload, setPayload] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [cheacked, SetIsCheacked] = useState([]);
  const [ValiDatePincode, SetIsValidatePincode] = useState(true);
  const [ActiveKey, SetIsActiveKey] = useState(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]);
  const [siteVisit, setsiteVisit] = useState([{ key: Math.random() }]);
  const [errorIndex, setErrorIndex] = useState();
  const [imagePreview, setImagePreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [uploadedDocs, setUploadedDocs] = useState([])
  const [selectedVendorList, setSelectedVendorList] = useState([])
  const [Proposal, setProposal] = useState([
    {
      question: "Other Proposal Documents",
      ans: false,
    },
  ]);
  const [comapny_particualr, setCompany_particular] = useState([
    {
      question: "Other Particular Documents",
      ans: false,
    },
  ]);

  useEffect(() => {
    setPayload(props.payload || {
      maximumBidders: 10,
    });
    setsiteVisit([{ key: Math.random() }]);
  }, []);

  const handleSelectVendor = (v) => {
    setSelectedVendorList(v)
  }

  const FileUploaded = (docs) => {
    setUploadedDocs(docs)
  }

  const handleServiceClick = (item) => {
    let payloadObj = { ...payload };
    let selectedServices = payloadObj.selectedServices || [];
    if (selectedServices?.find((service) => service.type == item.type)) {
      selectedServices = selectedServices.filter((s) => s.type !== item.type);
    } else {
      selectedServices.push({ title: item.title, type: item.type });
    }
    payloadObj.selectedServices = selectedServices;
    setPayloadObject(payloadObj);
  };

  const onClickAdd = () => {
    for (let i = 0; i < siteVisit?.length; i++) {
      let validation = CommonUtils.validateCreateTenderPayload(siteVisit[i], 6);

      if (validation?.error) {
        setErrorKey(validation.key);
        setErrorIndex(i);
        return;
      }
    }
    let payloadObj = [...siteVisit, { key: Math.random() }];
    setsiteVisit(payloadObj);
  };

  const onClickRemove = (index) => {
    let payloadObj = [...siteVisit];

    let len = payloadObj.length;

    if (len === 1) {
      payloadObj = [{ key: Math.random() }];
    } else {
      payloadObj.splice(index, 1);
    }
    setsiteVisit([...payloadObj]);
  };

  const handleSiteDetailChange = (value, key, index) => {
    let payloadObj = [...siteVisit];
    payloadObj[index][key] = value;

    if (key === 'maxVisit') {
      payloadObj[index][key] = parseInt(value) ?? 0;
    }
    setsiteVisit([...payloadObj]);
  };

  const handlePayloadChange = (value, key) => {
    if (key === "facilityPostalCode") {
      if (value.length === 6) {
        SetIsValidatePincode(true);
      } else {
        SetIsValidatePincode(false);
      }
    }
    let payloadObj = { ...payload };
    if (key === "maximumBidders") {
      if(/^[0-9]*$/.test(value)) {
        if(value > 0) {
          payloadObj[key] = value;
        }
      }
      if(value === "") {
        payloadObj[key] = value;
      }
    } else {
      payloadObj[key] = value;
    }
    setPayloadObject(payloadObj);
  };

  const handleRedio = (value) => {
    let payloadObj = { ...payload };
    payloadObj["selectedFacilityType"] = value;
    setPayloadObject(payloadObj);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "1");
  };

  const handleNext = () => {
    let validation = CommonUtils.validateCreateTenderPayload(payload, 1);
    if (payload.facilityPostalCode) {
      if (
        payload.facilityPostalCode.length > 6 ||
        payload.facilityPostalCode.length <= 5
      ) {
        return;
      }
    }
    if (validation?.error) {
      setErrorKey(validation.key);
      return;
    }
    props.next();
  };

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <button className="obx-date-picker-btn" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
        setImagePreview(e.target.files[0]);
        setShowPreview(true);
    
    //   reader.readAsDataURL(file);
    }
  };


 const handleRemoveImage = () => {
   setImagePreview('');
   setShowPreview(false);
 };


  const handleDateEvent = (event, picker, index, item) => {
    let payloadObj = [...siteVisit];
    payloadObj[index].startDate = picker.startDate.toDate();
    payloadObj[index].endDate = picker.endDate.toDate();

    setsiteVisit(payloadObj);
  };
  

  const UploadTenderpublish = (event) => {
    event.preventDefault();
    let payloadObj = { ...payload };

    // Start validation form
    let validation = CommonUtils.validateCreateTenderPayload(payload, 1);
    if (validation.error) {
      setErrorKey(validation.key);
      return;
    }
    if (Object.entries(payloadObj).length === 0) {
      return 
    }

    validation = !payloadObj.selectedServices ||  payloadObj.selectedServices.length === 0
    if (validation) {
      setErrorKey("selectedServices");
      return;
    }

    validation = CommonUtils.validateCreateTenderPayload(payload, 7);
    if (validation.error) {
      setErrorKey(validation.key);
      return;
    }



    // End validation form

    let filterSite = siteVisit.filter(d => Object.entries(d).length >= 3)
  
    const formData = new FormData();
    formData.append("file", imagePreview);
    formData.append("facilityName", payloadObj.facilityName);
    formData.append("facilityType", payloadObj.selectedFacilityType);
    formData.append("maximumBidders", payloadObj.maximumBidders);
    formData.append("contactName", payloadObj.contactName ??  '');
    formData.append("contactNumber", payloadObj.contactNumber ??  '');
    formData.append("facilityPostalCode", payloadObj.facilityPostalCode);
    formData.append("location", payloadObj.location);
    formData.append("publicationDate", payloadObj.publicationDate);
    formData.append("streetAddress", payloadObj.streetAddress);
    formData.append("submissionDate", payloadObj.submissionDate);
    formData.append("remarks", payloadObj.remarks);
    formData.append("selectedTender", payloadObj.selectedTender)
    formData.append("questioneries", JSON.stringify({
      proposal: Proposal,
      comapny_particualr: comapny_particualr
    }))
    if (filterSite.length > 0) {
      formData.append("siteDetails", JSON.stringify(filterSite))
    }

    if(uploadedDocs) {
      formData.append("additionalDocs", JSON.stringify(uploadedDocs ?? []))
    }

    if(selectedVendorList) {
      let vendorIds = selectedVendorList.map(v => v.id)
      // payload["vendorIds"] = vendorIds
      formData.append("vendorIds", JSON.stringify(vendorIds ?? []))
    }


    payloadObj.selectedServices.forEach((payloadObj, index)=>{
      Object.entries(payloadObj).forEach(([key, value]) => {
        formData.append(`selectedServices[${index}].${key}`, value);
      });
    })


     if(payloadObj.siteDetails){
      payloadObj.siteDetails.forEach((payloadObj, index)=>{
        Object.entries(payloadObj).forEach(([key, value]) => {
          formData.append(`siteDetails[${index}].${key}`, value);
        });
      })
    }
  
    fetch(`${Urls.BASE_URL}${Urls.CREATE_TENDER_CUSTOM}`, {
      method: 'POST',
      headers:{
        authorization: token,
      },
      body: formData, 
    })
    // TenderAPI.createTenderByCustom(formData)
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        window.localStorage.setItem("tenderNumber",data.tenderNumber)
        props.onPublishTender();
        // Handle success response
      })
      .catch(error => {
        // alert(error.message);
        console.error('Error:', error);
        return
        // Handle error
      });
  };
  

  return (
    <div>
      <span className="h6 header_title_caption fs-5">Upload Your Tenders</span>
      <hr></hr>
      <div className="card mt-3  header_title_caption tooltip-background-color">
        <div className="card-body p-4">
          <div className="">
            <div className="">
              <i className="me-2 bi bi-info-circle"></i>
              <small>
                This is to upload your own tender.
              </small>
            </div>
          </div>
          <div className="">
            <i className="me-2 bi bi-info-circle"></i>
            <small>
            Please fill in the details and upload your documents below.
            </small>
          </div>
        </div>
      </div>
      <Accordion activeKey={ActiveKey}>

        <Accordion.Item eventKey="0" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("0");
            }}
          >
            1. Select the type of facility.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className={`card mt-2 ${
                errorKey === "selectedFacilityType"
                  ? "invalid-input"
                  : ""
              }`}>
              <div className="card-body text-center">
                <div className="row text-center">
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("COMMERCIAL")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "COMMERCIAL"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./commercial.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Commercial</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      onClick={() => handleRedio("CONDOMINIUM")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "CONDOMINIUM"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto }}
                      >
                        <img
                          src="./condomium.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Condominium</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer "
                      // onClick={() => handleRedio("Industrial")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "Industrial"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./warehous.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Industrial</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("DORMITORY")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "DORMITORY"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./dormatiry.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Dormitory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-4 mt-2">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("Retail")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "Retail"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./retail.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Retail</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("1");
            }}
          >
            {" "}
            2. Enter the facility details.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className={`card mt-3 ${
                errorKey === "facilityName" || errorKey === "streetAddress" || errorKey === "facilityPostalCode"
                  ? "invalid-input"
                  : ""
              }`}>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput1"
                        className="form-label header_title_caption"
                      >
                        Facility Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "facilityName" ? "invalid-input" : ""
                        }`}
                        id="facilityControl"
                        value={payload?.facilityName}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "facilityName")
                        }
                      />
                    </div>
                  </div>
                  <div className="col">
                  <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Street Address
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "streetAddress" ? "invalid-input" : ""
                        }`}
                        id="streetAddressControl"
                        value={payload?.streetAddress}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "streetAddress")
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                  <div className="mb-3">
                      <label
                        htmlFor="formControlInput2"
                        className="form-label header_title_caption"
                      >
                        Facility Postal Code
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          errorKey == "facilityPostalCode"
                            ? "invalid-input"
                            : ""
                        }${ValiDatePincode ? "" : "pincode_verification"}`}
                        id="postalCodeControl"
                        value={payload?.facilityPostalCode}
                        onChange={(e) =>
                          handlePayloadChange(
                            e?.target?.value,
                            "facilityPostalCode"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("9");
            }}
          >
            3. Publication Method
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-3 header_title_caption ${
                errorKey === "selectedTender" ? "invalid-input" : ""
              } `}
            >
              <div className="card-body">
                <div className="row">
                  <div class="col ">
                    <div
                      className="form-check"
                      onClick={() =>
                        handlePayloadChange("OPEN", "selectedTender")
                      }
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={payload.selectedTender === "OPEN"}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Open Tender
                      </label>
                      <div className="mt-2 ">
                        <small>
                          Choose this option if you would like to publish the
                          tender on our website directory and make it visible to
                          all our vendors
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col ">
                    <div
                      className="form-check"
                      onClick={() =>
                        handlePayloadChange("CLOSED", "selectedTender")
                      }
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioClosed"
                        checked={payload.selectedTender === "CLOSED"}
                      />
                      <label class="form-check-label" for="flexRadioClosed">
                        Closed Tender
                      </label>
                      <div className="mt-2">
                        <small>
                          Choose this option if you would like to restrict
                          applicants to a pre-selected list.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              payload.selectedTender === "CLOSED" &&
                <div className={`card mt-3`}>
                  <div  className="card-header">
                    <h5 className="card-title">Select Vendor</h5>
                  </div>
                  <div className="card-body">
                    <VendorCard
                      handleSelectVendor={handleSelectVendor}
                      selectedVendorList={selectedVendorList}
                    />
                  </div>
                </div>
            }
            
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("2");
            }}
          >
            {" "}
            4. Enter the maximum bidders.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className={`card mt-3 ${
                errorKey === "maximumBidders"
                  ? "invalid-input"
                  : ""
              }`}>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Maximum Number of Bidders
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          errorKey == "maximumBidders" ? "invalid-input" : ""
                        }`}
                        id="maxBiddersControl"
                        value={payload?.maximumBidders}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "maximumBidders")
                        }
                      />
                      {errorKey === "maximumBidders" ? (
                        <span style={{ color: "red" }}>Enter Number of Bidder minimum 1.</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="border-0 mt-2">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("3");
            }}
          >
            5. Key Dates
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-3 header_title_caption  ${`${
                errorKey === "publicationDate" || errorKey === "submissionDate"
                  ? "invalid-input"
                  : ""
              }`}`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                  
                    <label htmlFor="formControlInput1" className="form-label">
                        Publication Date
                      </label>
                      <div classname ="col"> 
                        <DatePicker wrapperClassName="datePicker"
                   
                   selected={payload.publicationDate}
                   showTimeSelect
                   minDate={new Date()}
                   closeOnScroll={true}
                   timeFormat="HH:mm"
                   dateFormat="MMMM d, yyyy h:mm aa"
                   onChange={(date) =>
                     handlePayloadChange(date, "publicationDate")
                   }
                   customInput={
                     <input
                       type="text" // Change to text for consistent styling
                       className={`form-control ${
                         errorKey === "publicationDate" ? "invalid-input" : ""
                       }`}
                       readOnly // Prevent manual input since it's a date picker
                       value={payload.publicationDate ? payload.publicationDate.toLocaleString() : ""}
                       placeholder="Select Publication Date"
                     />
                   }
                   
                 />
                     
                 
                    </div>
                     
                  </div>
                  <div className="col">
                 <label htmlFor="formControlInput2" className="form-label">
                        Submission Deadline
                      </label>
                        <div> 
                        <DatePicker wrapperClassName="datePicker"
                        selected={payload.submissionDate}
                        showTimeSelect
                        minDate={new Date()}
                        closeOnScroll={true}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        onChange={(date) =>
                          handlePayloadChange(date, "submissionDate")
                        }
                        customInput={
                          <input
                            type="text" // Change to text for consistent styling
                            className={`form-control ${
                              errorKey === "submissionDate" ? "invalid-input" : ""
                            }`}
                            readOnly // Prevent manual input since it's a date picker
                            value={payload.submissionDate ? payload.submissionDate.toLocaleString() : ""}
                            placeholder="Select Submission Deadline"
                          />
                        }

                      />
                   
                     
                 
                    </div>
                      
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="border-0 mt-2">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("4");
            }}
          >
            6. Site Visit (Optional)
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className="card mt-3 header_title_caption">
            
              {siteVisit?.length
                ? siteVisit.map((item, index) => (
                  <div key={item.key}>
                    <div className='card-body header_title_caption'>
                      {siteVisit?.length >= 1 && item?.startDate ? (
                        <div
                          className='cross-icon'
                          onClick={() => onClickRemove(index)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className='row'>
                        <div className='col'>
                          <DateRangePicker
                          onHide={(event, picker) =>
                            handleDateEvent(event, picker, index)
                          }
                            onCancel={() => {}}
                            autoApply
                            initialSettings={{
                              singleDatePicker: false,
                              timePicker: true,
                              timePicker24Hour: true,
                              autoUpdateInput: false,
                            }}
                            locale={{
                              format: "DD/MM/YYYY",
                            }}
                          >
                            <div className='mb-3'>
                              <label
                                htmlFor='formControlInput2'
                                className='form-label'
                              >
                                Date and Time
                              </label>
                              <input
                                type='text'
                                className={`form-control ${
                                  (errorKey == "startDate" ||
                                    errorKey == "endDate") &&
                                  errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                }`}
                                placeholder='Please select the date and time'
                                value={
                                  item.startDate
                                    ? `${CommonUtils.datePipe(
                                        item?.startDate,
                                        "dd/MM/yyyy, hh:mm"
                                      )} - ${CommonUtils.datePipe(
                                        item?.endDate,
                                        "dd/MM/yyyy, hh:mm"
                                      )}`
                                    : ""
                                }
                              />
                            </div>
                          </DateRangePicker>
                        </div>

                        {/* <div className='col-md-4 col-lg-4 col-sm-12'>
                          <div className='mb-3'>
                            <label htmlFor='formControlInput1' className='form-label'>
                              Location
                            </label>
                            <textarea
                              type='text'
                              className={`form-control ${
                                errorKey == "location" && errorIndex === index
                                  ? "invalid-input"
                                  : ""
                              }`}
                              id='facilityControl'
                              value={item?.location}
                              onChange={(e) =>
                                handleSiteDetailChange(
                                  e?.target?.value,
                                  "location",
                                  index
                                )
                              }
                            />
                          </div>
                        </div> */}

                        <div className='col'>
                          <div className='mb-3'>
                            <label htmlFor='formControlInput1' className='form-label'>
                            Limit number of vendors per visit
                            </label>
                            <input
                              type='number'
                              className={`form-control ${
                                errorKey == "maxVisit" && errorIndex === index
                                  ? "invalid-input"
                                  : ""
                              }`}
                              id='facilityControl'
                              value={item?.maxVisit}
                              onChange={(e) =>
                                handleSiteDetailChange(
                                  e?.target?.value,
                                  "maxVisit",
                                  index
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
              <div className='text-center pb-4'>
                <button
                  onClick={onClickAdd}
                  type='button'
                  className='btn btn-success'
                >
                  + Add
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5" className="border-0 mt-2 ">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("5");
            }}
          >
            7. Contact Person (Optional)
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-4 header_title_caption ${
                errorKey === "contactName" || errorKey === "contactNumber"
                  ? "invalid-input"
                  : ""
              }`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Contact Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "contactName" ? "invalid-input" : ""
                        }`}
                        id="contactNameControl"
                        value={payload?.contactName}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "contactName")
                        }
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Contact Number
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "contactNumber" ? "invalid-input" : ""
                        }`}
                        id="contactNumberControl"
                        value={payload?.contactNumber}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "contactNumber")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="6" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("6");
            }}
          >
            8. Select the type of security services required.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className={`card mt-4 ${
                errorKey === "selectedServices"
                  ? "invalid-input"
                  : ""
              }`}>
              <div className="card-body">
                <table className="table ">
                  <thead>
                    <tr className="header_title_caption">
                      <th scope="col" colSpan={2} className="text-center">
                        Services
                      </th>
                      <th scope="col" colSpan={2} className="text-center">
                        Select this service if...
                      </th>
                    </tr>
                  </thead>
                  <tbody className="hover selected-hover">
                    {props?.services ? (
                      props.services?.map((item, index) => (
                        <tr
                          className={`${
                            payload?.selectedServices?.find(
                              (s) => s.type == item.type
                            )
                              ? "options-selected-color  header_title_caption"
                              : "header_title_caption"
                          }`}
                          // style={{
                          //   background: `${
                          //     index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                          //   }`,
                          // }}
                          onClick={() => {
                            handleServiceClick(item);
                          }}
                        >
                          <td colSpan={2}>
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                className="me-3"
                                checked={
                                  payload?.selectedServices?.find(
                                    (s) => s.type == item.type
                                  )
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  e.stopPropagation();

                                  handleServiceClick(item);
                                }}
                              />

                              <span className="me-4 ms-2">
                                <img
                                  src={SecurityServices[index]}
                                  style={{ width: "30px" }}
                                  alt="logo"
                                />
                              </span>
                              {item?.title}
                            </div>
                          </td>
                          <td colSpan={2}>{item?.description}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No services found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("7");
            }}
          >
            9. Additional Remarks
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
          <div className="card mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col">
                <div className="mb-3">
                      <label
                        htmlFor="formControlInput1"
                        className="form-label header_title_caption"
                      >
                        Remarks
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "remarks" ? "invalid-input" : ""
                        }`}
                        id="remarks"
                        value={payload?.remarks}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "remarks")
                        }
                      />
                    </div>


                </div>
              </div>
            </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8" className="border-0 mt-2 ">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("8");
            }}
          >
            10. Upload Additional file (Please use 'Upload Tender' button below to upload your tender)
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <MultiFileUpload onUploaded={FileUploaded} />
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>


      <form id="myform">
        <div className={`yes ${showPreview ? 'it' : ''}`}>
          <div>
          <label htmlFor="imag" className="btn_upload">
            <input
              type="file"
              id="imag"
              title=""
              className="input-img"
              accept=".pdf, .doc, .docx,"
              onChange={handleImageChange}
            />
            Upload Tender
          
          </label>
            <p>{imagePreview.name}</p>
          </div>
        <div>
        {showPreview && (
            <>
              {/* <img id="ImgPreview" src={imagePreview} className="preview1" alt="Preview" />
              <input
                type="button"
                id="removeImage1"
                value="x"
                className="btn-rmv1"
                onClick={handleRemoveImage}
              /> */}
            
              <button 
              type="button"
              className="btn btn-sm btn-primary obx-btn-color  mr-2"
              onClick={(e)=>{UploadTenderpublish(e)}}>{" "}
              <FontAwesomeIcon icon={faGlobe} /> &nbsp;Publish Tender</button>
            </>
          )} 
        </div>
          
        </div>
      </form>
    </div>
  );
};

export default BuildingParticular;
