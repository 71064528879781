import React, { useState, useEffect } from "react";
import TenderAPI from "../../apis/tender";
import Loading from "../common/loading/loading";
import PaginationComponent from "../common/pagination/pagination";
import NoRecords from "../no-records/no-records";
import "./announcement.scss";

function Announcement(props) {
  const [items, setItems] = useState([]);
  const [pageObject, setPageObject] = useState({});
  const [isLoading, setLoader] = useState(false);

  useEffect(() => {
    getAnnouncement({ page: 1 });
  }, []);

  const getAnnouncement = (payload = {}) => {
    if (!payload.page) payload.page = pageObject.currentPage || 1;

    pageObject.currentPage = payload.page;

    setLoader(true);

    TenderAPI.getAnnouncement(payload)
      .then((response) => {
        setLoader(false);
        setPageObject(response?.pagination || {});
        setItems(response.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage;
    pageObject.startIndex = startIndex;
    setPageObject(pageObject);
    getAnnouncement({ page: selectedPage });
  };

  return (
    <div className="container py-4">
      <div className="container-fluid">
        {isLoading ? <Loading /> : ""}
        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>Announcements</h4>
            </div>
          </div>
        </div>
        {/* <div className="">

                    {
                        items?.length ?
                            items?.map(item => (
                                <div className="card mt-4">
                                    <div className="card-header">{item.title}
                                    </div>
                                    <div className="card-bord">
                                        <div className="p-4">{item.body}</div>
                                    </div>
                                </div>
                            )) :
                            <NoRecords />
                    }
                </div> */}

          <div className="title">
          <h1>NEW FEATURE ADDED</h1>
          <h3>We have added a new feature to OBX. CLose tender!</h3>
          <figure className="pt-5 pb-5">
            <img src="close tender.jpg" alt="Close Tender" className="small_image"/>
            <figcaption style={{ fontSize: "12px" }}>
              Photo of a close tender
            </figcaption>
          </figure>
        </div>
        <div className="pb-5 text-center">
       
          <p>
            Buyers, you can use the close tender to select the desired vendor to bid for your tenders!
          </p>
          <p>
            It is just a few clicks and you are able to select the vendors you desired!
          </p>
          <p>
            Vendors! Hurry and update your profile! 
          </p>
            Dont miss any chances of being selected!
        </div>
        <hr/>
     

        <div className="title">
          <h1>LAUNCH OF OBX</h1>
          <figure>
            <img src="OBX Launch.png" alt="OBX Launch" />
            <figcaption style={{ fontSize: "12px" }}>
              15 Nov 2023: Launch of OBX during TP-SII Security Industry
              Conference 2023 by MOS Ms. Sun Xueling
            </figcaption>
          </figure>
        </div>
        <div>
          <p>
            We are proud to announce the official lauch of OBX by MOS Ms. Sun
            Xueling on 15 November 2023 during the TP-SII Security Industry
            Conference 2023.
          </p>
          <p>
            <strong>Quoting from MOS Ms. Sun's speech</strong>
          </p>
          <p>
            So today, I am announcing the launch of the Security Association
            Singapore (SAS) Online Outcome-based Tender Generator and Repository
            (OBX) today. Developed in collaboration with the Singapore Police
            Force, the OBX is a resource for security buyers to create and
            publish customised outcome-based tenders. It also allows security
            agencies to propose competitive solutions that combine manpower,
            technology and processes to boost efficiency. The trials conducted
            by SAS have been very positive. We applaud SAS in leading the way
            for this initiative, and would also like to thank Enterprise
            Singapore for funding it. Let us give them a round of applause.
          </p>
          <a href="https://www.mha.gov.sg/mediaroom/speeches/security-industry-conference-2023/">
            Click here to read more.
          </a>
        </div>
        <div className="pt-4">
          <PaginationComponent
            startIndex={pageObject?.startIndex || 1}
            selectedPage={pageObject?.currentPage || 1}
            totalPages={pageObject?.totalPages || 0}
            onPageSelected={onPageSelected.bind(this)}
          />
        </div>
      </div>
    </div>
  );
}

export default Announcement;

