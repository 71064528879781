import { useMemo, useEffect, useState } from "react";
import "./confirmation.css";
import { useLocation, useHistory } from "react-router-dom";
import imagesucess from "../../assets/images/img-wrap.png";
import TenderDetail from "../vendor/tender-detail/tender-detail";
import Path from "../routes/routes-path";

const ConfirmationTender = () => {
  const { search } = useLocation();
  const [isViewTender, setViewTender] = useState(false);
  const data = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const tenderParameter = data.has("tenderNumber");
  const tenderNumber = data.get("tenderNumber");

  const handleBack = () => {
    setViewTender(false);
  };
  

  const handleTendetails = () => {
    setViewTender(true);
  };
  const onClickLogin = () => {
    history.push(Path.LOGIN);
  };
  useEffect(() => {
    if (!tenderParameter) {
      history.push("/login");
    }
  }, [tenderParameter, history]);
  return (
    <>
      {isViewTender ? (
        <div className='container opportunities'>
          <div className='container-fluid py-4'>
            <div className='card shadow-sm obx-blue p-4 mb-4'>
              <div className='row'>
                <div className='col obx-white'>
                  <h4>Opportunities</h4>
                </div>
              </div>
            </div>
            <TenderDetail
              tender={tenderNumber}
              onClickLogin={onClickLogin}
              back={handleBack}
            />
          </div>
        </div>
      ) : (
        <>
          <section className='bid-submission-wrapper'>
            <div className='bid-submision'>
              <div className='submission-wrap'>
                <h2>Tender Submission</h2>
              </div>
              <div className='payment-sucss'>
                <div className='payment-sucss1'>
                  <div className='sucess-wrap'>
                    <h3>Publish success!</h3>
                    <p>
                      The tender Id# {data.get("tenderNumber")} has been successfully published.
                    </p>
                    <p>
                      You may proceed to submit your proposal documents by{" "}
                      <span
                        style={{ color: "#324a98", cursor: "pointer" }}
                        onClick={handleTendetails}
                      >
                        {" "}
                        clicking here
                      </span>
                    </p>
                  </div>
                  <div className='imgwrap'>
                    <img src={imagesucess} alt />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ConfirmationTender;
