import { auto } from "@popperjs/core";
import { useEffect, useState } from "react";
import CommonUtils from "../../../utility/common-utilities";
import "../../../styles/fonts.scss";
import "../create-tender-layout.scss";
import Accordion from "react-bootstrap/Accordion";
import SecurityServices from "../../../constants/securityservicelogo";

const BuildingParticular = (props) => {
  const [payload, setPayload] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [cheacked, SetIsCheacked] = useState([]);
  const [ValiDatePincode, SetIsValidatePincode] = useState(true);
  const [ActiveKey, SetIsActiveKey] = useState(["0", "1", "2", "3"]);
 

  useEffect(() => {
    setPayload(props.payload || {
      maximumBidders: 10
    });
  }, []);

  const handleServiceClick = (item) => {
    let payloadObj = { ...payload };
    let selectedServices = payloadObj.selectedServices || [];
    if (selectedServices?.find((service) => service.type == item.type)) {
      selectedServices = selectedServices.filter((s) => s.type !== item.type);
    } else {
      selectedServices.push({ title: item.title, type: item.type });
    }
    payloadObj.selectedServices = selectedServices;
    setPayloadObject(payloadObj);
  };

  const handlePayloadChange = (value, key) => {
    if (key === "facilityPostalCode") {
      if (value.length === 6) {
        SetIsValidatePincode(true);
      } else {
        SetIsValidatePincode(false);
      }
    }

    let payloadObj = { ...payload };
    if (key === "maximumBidders") {
      if(/^[0-9]*$/.test(value)) {
        if(value > 0) {
          payloadObj[key] = value;
        }
      }
      if(value === "") {
        payloadObj[key] = value;
      }
    } else {
      payloadObj[key] = value;
    }
    setPayloadObject(payloadObj);
  };

  const handleRedio = (value) => {
    let payloadObj = { ...payload };
    payloadObj["selectedFacilityType"] = value;
    setPayloadObject(payloadObj);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "1");
  };

  const handleNext = () => {
    let validation = CommonUtils.validateCreateTenderPayload(payload, 1);
    if (payload.facilityPostalCode) {
      if (
        payload.facilityPostalCode.length > 6 ||
        payload.facilityPostalCode.length <= 5
      ) {
        return;
      }
    }
    if (validation?.error) {
      setErrorKey(validation.key);
      return;
    }
    props.next();
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">Building Particulars</span>
      <hr></hr>
      <div className="card mt-3  header_title_caption tooltip-background-color">
        <div className="card-body p-4">
          <div className="">
            <div className="">
              <i className="me-2 bi bi-info-circle"></i>
              <small>
                This is the Outcome-Based Tender Generator. Please complete the
                following questions (10-15 minutes) to generate your document.
              </small>
            </div>
          </div>
          <div className="">
            <i className="me-2 bi bi-info-circle"></i>
            <small>
              Please note: discarded questionnaires cannot be recovered at this
              time.
            </small>
          </div>
        </div>
      </div>
      <Accordion activeKey={ActiveKey}>
      
      <Accordion.Item eventKey="2" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("2");
            }}
          >
            1. Select the type of facility.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className="card mt-2">
              <div className="card-body text-center">
                <div className="row text-center">
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("COMMERCIAL")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "COMMERCIAL"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./commercial.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Commercial</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      onClick={() => handleRedio("CONDOMINIUM")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "CONDOMINIUM"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto }}
                      >
                        <img
                          src="./condomium.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Condominium</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer "
                      // onClick={() => handleRedio("Industrial")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "Industrial"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./warehous.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Industrial</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 col col-md-4">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("DORMITORY")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "DORMITORY"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./dormatiry.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Dormitory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-4 mt-2">
                    <div
                      className="form-check form-check-inline cursor-pointer"
                      // onClick={() => handleRedio("Retail")}
                    >
                      <div
                        className={`card ${
                          payload.selectedFacilityType === "Retail"
                            ? "selected-facility-color"
                            : ""
                        } `}
                        style={{ width: "9rem", margin: auto, opacity: 0.5 }}
                      >
                        <img
                          src="./retail.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body header_title_caption">
                          <p className="card-text">Retail</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("1");
            }}
          >
            {" "}
            2. Enter the facility details.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput1"
                        className="form-label header_title_caption"
                      >
                        Facility Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "facilityName" ? "invalid-input" : ""
                        }`}
                        id="facilityControl"
                        value={payload?.facilityName}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "facilityName")
                        }
                      />
                    </div>
                  </div>

                  <div className="col">
                  <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Street Address
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errorKey == "streetAddress" ? "invalid-input" : ""
                        }`}
                        id="streetAddressControl"
                        value={payload?.streetAddress}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "streetAddress")
                        }
                      />
                    </div>
                   
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                  <div className="mb-3">
                      <label
                        htmlFor="formControlInput2"
                        className="form-label header_title_caption"
                      >
                        Facility Postal Code
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          errorKey == "facilityPostalCode"
                            ? "invalid-input"
                            : ""
                        }${ValiDatePincode ? "" : "pincode_verification"}`}
                        id="postalCodeControl"
                        value={payload?.facilityPostalCode}
                        onChange={(e) =>
                          handlePayloadChange(
                            e?.target?.value,
                            "facilityPostalCode"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
       
        <Accordion.Item eventKey="0" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("0");
            }}
          >
            {" "}
            3. Enter the maximum bidders.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label
                        htmlFor="formControlInput3"
                        className="form-label header_title_caption"
                      >
                        Maximum Number of Bidders
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          errorKey == "maximumBidders" ? "invalid-input" : ""
                        }`}
                        id="maxBiddersControl"
                        value={payload?.maximumBidders}
                        onChange={(e) =>
                          handlePayloadChange(e?.target?.value, "maximumBidders")
                        }
                      />
                      {errorKey === "maximumBidders" ? (
                        <span style={{ color: "red" }}>Enter Number of Bidder minimum 1.</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("3");
            }}
          >
            4. Select the type of security services required.
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div className="card mt-4 table_style">
              <div className="card-body">
                <table className="table ">
                  <thead>
                    <tr className="header_title_caption tables_header_rowborderbottom">
                      <th scope="col" colSpan={2} className="text-center">
                        Services
                      </th>
                      <th scope="col" colSpan={2} className="text-center">
                        Select this service if...
                      </th>
                    </tr>
                  </thead>
                  <tbody className="hover selected-hover">
                    {props?.services ? (
                      props.services?.map((item, index) => (
                        <tr
                          className={`${
                            payload?.selectedServices?.find(
                              (s) => s.type == item.type
                            )
                              ? "options-selected-color  header_title_caption"
                              : "header_title_caption"
                          }`}
                          // style={{
                          //   background: `${
                          //     index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                          //   }`,
                          // }}
                          onClick={() => {
                            handleServiceClick(item);
                          }}
                        >
                          <td colSpan={2}>
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                className="me-3"
                                checked={
                                  payload?.selectedServices?.find(
                                    (s) => s.type == item.type
                                  )
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  e.stopPropagation();

                                  handleServiceClick(item);
                                }}
                              />

                              <span className="me-4 ms-2">
                                <img
                                  src={SecurityServices[index]}
                                  style={{ width: "30px" }}
                                  alt="logo"
                                />
                              </span>
                              {item?.title}
                            </div>
                          </td>
                          <td colSpan={2}>{item?.description}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No services found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={handleNext}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuildingParticular;
