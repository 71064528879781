import React, { useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';

const VendorList = (props) => {
  const { 
    isLoading, 
    data = [], 
    observerRef, 
    unableRemoved, 
    onRemoveVendor= () => {}, 
    onSelectVendor 
  } = props
  
  return (
    <>
      <ListGroup className={"vendor-list"} variant="flush">
        {
          data.map((v, i) => {
            return (
              <ListGroup.Item 
                key={`vendor_idx_${i}`} 
                className="d-flex justify-content-between align-items-center" 
                onClick={() => {
                  if(!unableRemoved) {
                    onSelectVendor(v)
                  }
                }}
              >
                {v.company}
                {
                  unableRemoved &&
                  <Button 
                    variant="danger" 
                    size="sm" 
                    onClick={() => onRemoveVendor(v)}
                  >
                    X
                  </Button>
                }
              </ListGroup.Item>
            )
          })
        }
      </ListGroup>

      {isLoading && <p>Loading...</p>}
      <div ref={observerRef} style={{ height: "1px", backgroundColor: "transparent" }}></div>
    </>
  );
}

export default VendorList;