import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
// import './asyncDropdown.scss';
// import "./asyncDropdown.scss"

const AsyncDropdown = ({
  asyncOpt = false,
  data = [],
  label,
  className,
  endpoint,
  handleChange,
  value
}) => {
  const [page, setPage] = useState(1); // Mengelola halaman data yang sedang dimuat
  const [hasMore, setHasMore] = useState(true); // Mengelola apakah masih ada data untuk dimuat
  const [options, setOptions] = useState([]); // Menyimpan opsi yang dimuat
  
  // Fungsi untuk memuat data dengan async/await
  const loadOptions = async (inputValue) => {
    try {
      const response = await fetch(`${endpoint}&page=${page}&limit=10`);
      const data = await response.json();

      const newOptions = data.results.map(item => ({
        value: item.id,
        label: item.name,
      }));

      // Tambahkan data baru ke opsi yang sudah ada
      setOptions(prevOptions => [...prevOptions, ...newOptions]);

      // Jika data yang diterima kurang dari 10, tidak ada lagi data untuk dimuat
      if (newOptions.length < 10) {
        setHasMore(false);
      }

      return newOptions; // Kembalikan opsi yang baru dimuat untuk digunakan oleh AsyncSelect
    } catch (error) {
      console.error('Error fetching data:', error);
      return []; // Jika ada error, kembalikan array kosong
    }
  };

  return <div 
      className={`async-select ${className}`}
      style={{
        border: '1px solid #dee2e6',
        borderRadius: '0.375rem',
        padding: '.375rem .75rem',
      }}
    >
      <label style={{fontSize: "0.85rem", color: "#737679", lineHeight: 1}}>{label ?? "Select"}</label>
      {
        asyncOpt
        ? <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          isMulti
          onMenuScrollToBottom={async () => {
            if (hasMore) {
              setPage(prevPage => prevPage + 1); 
              await loadOptions('');
            }
          }}
          options={options} 
          value={value}
          onChange={handleChange}
        />
        : <Select
            isMulti
            name="select-opt"
            options={data || []}
            className="basic-multi-select"
            classNamePrefix="select"
            value={value}
            onChange={handleChange}
          />
      }
    </div>
};

export default AsyncDropdown;
