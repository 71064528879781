import Login from "../authentication/login/login";
import Register from "../authentication/register/register";
import Opportunities from "../vendor/opportunities/opportunities";

import { Router, Switch } from "react-router-dom";
import CreateTender from "../create-tender/create-tender";
import Dashboard from "../dashboard/dashboard";
import ADMDashboard from "../admin/dashboard/dashboard";
import ADMLogin from "../admin/login-admin/login-admin";
import ContactUs from "../contact-us/contact-us";

import Route from "../routes/route-wrapper";
import Path from "../routes/routes-path";
import ForgotPassword from "../authentication/forgot-password/forgot-password";
import VendorDirectory from "../vendor-directory/vendor-directory";
import FAQ from "../faq/faq";
import Profile from "./profile/profile";
import VendorDashboard from "../vendor/dashboard/dashboard";
import SitePage from "../site/site";
import Announcement from "../announcement/announcement";
import PaymentSucess from "../Payment/PaymentSucess";
import BuyerTranasction from "../transactions/buyer-transactions";
import VendorTranasction from "../transactions/vendor-transactions";
import VerifyUser from "../authentication/verifyUser/verifyUser";
import BuyerList from "../buyerlist/buyerlist";
import UploadTender from "../uploadTender/uploadTender";
import Privacy from "../Privacy/Privacy";
import Terms from "../Terms/Terms";
import ConfirmationTender from "../confirmation/confirmation-tender";


const Main = () => {
  return (
    <Switch>
      <Route exact path={Path.BASE} component={SitePage} />
      <Route exact path={Path.BUYER_LIST} component={BuyerList} />
      <Route exact path={Path.DASHBOARD} component={VendorDashboard} />
      <Route exact path={Path.BUYER_DASHBOARD} component={Dashboard} />
      <Route exact path={Path.LOGIN} component={Login} />
      <Route exact path={Path.REGISTER} component={Register} />
      <Route exact path={Path.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={Path.OPPORTUNITIES} component={Opportunities} />
      <Route exact path={Path.VENDOR_DIRECTORY} component={VendorDirectory} />
      <Route exact path={Path.CONTACT} component={ContactUs} />
      <Route exact path={Path.CREATE_TENDER} component={CreateTender} />
      <Route exact path={Path.UploadTENDER} component={UploadTender} />
      <Route exact path={Path.TENDERCONFIRMATION} component={ConfirmationTender} />
      <Route exact path={Path.FAQ} component={FAQ} />
      <Route exact path={Path.ANNOUNCEMENTS} component={Announcement} />
      <Route exact path={Path.PROFILE} component={Profile} />
      <Route exact path={Path.BUYERSUCCESS} component={PaymentSucess} />
      <Route exact path={Path.VENDORSUCCESS} component={PaymentSucess} />
      <Route exact path={Path.BUYERTRANSACTION} component={BuyerTranasction} />
      <Route
        exact
        path={Path.VENDORTRANSACTION}
        component={VendorTranasction}
      />
      <Route exact path={Path.VERIFICATION} component={VerifyUser} />
      <Route exact path={Path.TERMS} component={Terms} />
      <Route exact path={Path.PRIVACY} component={Privacy} />

      <Route exact path={Path.ADMIN_LOGIN} component={ADMLogin} />
      <Route exact path={Path.ADM_DASHBOARD} component={ADMDashboard} />
    </Switch>
  );
};

export default Main;
