import React, { useState } from 'react';
import './file-list.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const FileItem = ({ 
  name, 
  icon, 
  onClickLink, 
  link, 
  id,
  isCanDelete,
  onDelete
}) => {

  return (
    <li
      className={`file-item`}
    >
      {
        isCanDelete &&
          <div 
            className={"delete-ic"}
            onClick={() => {
              onDelete(id)
              console.log("remove "+ id)
            }}
          >x</div>
      }
      <a
        className="pdf-link"
        href={onClickLink ? undefined : link}
        onClick={onClickLink ? onClickLink(link) : undefined}
        target={onClickLink ? undefined : "_blank"}
        rel={onClickLink ? undefined : "noopener noreferrer"}
        title={name}
      >

        { icon
          ? <img src={icon} alt={name} />
          : <FontAwesomeIcon
            className='file-icon'
            icon={faFileAlt}
          />
          }
        <p className='link-name'>{name}</p>
      </a>
    </li>
  );
};

const FileList = ({ 
  files={}, 
  onClickLink, 
  onDelete, 
  isCanDelete=false
}) => {
  return (
    <ul className="file-list">
      {files.map((file, idx) => (
        <FileItem 
          key={idx} 
          id={file.id} 
          name={file.pdfDocumentKey}
          link={file.pdfDocumentUrl} 
          icon={file.icon} 
          onClickLink={onClickLink}
          isCanDelete={isCanDelete}
          onDelete={onDelete}
        />
      ))}
    </ul>
  );
};

export default FileList;