
import ServerConfigs from "./server-config";
const Urls = {
  BASE_URL: ServerConfigs.url,

  CONTACT: "/api/v1/contactus",

  SIGNED_URL: "/api/v1/signurl/put",
  AWS_SIGNED_URL: "/api/v1/signurl",
  BUYER_LIST: "/buyer-list",

  IS_LOGGED_IN: "/api/v1/user/loggedin",
  LOGIN: "/api/v1/authentication/login",
  LOGOUT: "/api/v1/authentication/logout",
  SIGNUP: "/api/v1/authentication/register",
  REQUEST_FORGOT_PASSWORD: "/api/v1/authentication/forgot/request",
  VERIFY_FORGOT_PASSWORD: "/api/v1/authentication/forgot/verify",
  ResendVerifyLink: "/user/v1/authentication/resend/verify",

  PUBLIC_TENDERS: "/api/v1/tender/opportunities",
  VENDORS: "/api/v1/vendors",
  ANNOUNCEMENT: "/api/v1/announcements",

  RESET_PASSWORD: "/api/v1/authentication/reset",
  EMAIL_VERIFY: "/api/v1/authentication/email/verify",
  PROFILE: "/api/v1/profile",
  UPLOADPROFILE: "/api/v1/upload_profile",
  SERVICES: "/api/v1/services",

  OUTCOMES: "/api/v1/outcome",
  KPIS: "/api/v1/kpis",

  CREATE_TENDER: "/api/v1/tender/invitation",
  PREVIEW_URL: "/api/v1/tender/preview",
  EDIT_URL: "/api/v1/tender/edit",

  PUBLISH_TENDERS: "/api/v1/tender/publish",

  GET_BUYER_TENDERS: "/api/v1/tender/buyer/invitations",
  BUYER_TENDERS_DETAILS: "/api/v1/tender/buyer/invitation/detail",

  TENDER: "/api/v1/tender",
  TENDER_STATUS: "/api/v1/tender/status",

  ITT_TENDER: "/api/v1/tender/submission",
  ITT_TENDERS_DETAILS: "/api/v1/tender/detail",
  ITT_TENDER_SUBMISSION_DETAILS: "/api/v1/tender/submission/detail",
  ITT_TENDER_SUB_DETAILS: "/api/v1/tender/submission/detail/id",

  WATCH_LIST: "/api/v1/tender/watch",
  AWARD_TENDER: "/api/v1/tender/award",

  MESSAGE: "/api/v1/message/post",
  GET_MESSAGE: "/api/v1/messages",
  MESSAGE_CONVERSATION: "/api/v1/message/conversation",
  ITT_TENDER_FOR_BUYER: "/api/v1/tender/submission/buyer",

  USER_KPI: "/api/v1/kpi/user",

  BUYER_INVOICES: "/api/v1/tender/buyer/invoices",
  VENDOR_INVOICES: "/api/v1/tender/submission/invoices",

  ITT_TENDER_CATEGORY: "/api/v1/tender/category",
  CREATE_TENDER_CUSTOM:"/api/v1/tender/invitation_custom",
  UPLOADFILE:"/api/v1/tender/upload_custom_file",
  DELETEFILE: "/api/v1/tender/remove_custom_file",

  // Administator Setting
  BUYER_SUBSCRIPTION_SETTING: "/api/v1/settings/buyer/subscription",
  BUYER_TENDER_PAYMENT_SETTING: "/api/v1/settings/buyer/tender-payment",
  VENDOR_SUBSCRIPTION_SETTING: "/api/v1/settings/vendor/subscription",
  VENDOR_BIDDING_PAYMENT_SETTING: "/api/v1/settings/vendor/bidding-payment",
  TENDER_SETTING: "/api/v1/settings/tender",

  //Tender Appointment
    CHECK_USER_APPOINTMENT_AVAILABILITY: "/api/v1/appointment/user-visit-availability",
    CREATE_APPOINTMENT: "/api/v1/appointment/create-appointment"

};

export default Urls;
